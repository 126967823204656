import { Reference } from '@apollo/client';
import {
  AddClearableInput,
  API,
  ClearableFieldsFragment,
  ClearableFieldsFragmentDoc,
  GstInfo,
  ListClearablesQueryVariables,
  PayeeType,
  RecurrenceFieldsFragmentDoc,
  RecurrenceInput,
  useAddClearableMutation,
} from 'api';
import { useAuth } from 'context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useNotification } from 'hooks/useNotification';
import { UploadError, UploadSuccess } from 'hooks/useUploadFiles';
import { find } from 'lodash';
import { useFinancialSettings } from 'pages/accounting/hooks';
import { useState } from 'react';
import { ensureArray, safeSum, spreadListIf } from 'system';
import { useAccountingContext } from '../context';
import { clearablesFilterMatches, storeFieldNameArgs } from '../utils';
import { useGlMapping } from './useGlMapping';

export type InvoiceLine = {
  unitId?: string;
  glId: string;
  description: string;
  invoiceAmounts: number;
};

export type CreateInvoiceFormFields = {
  payee: PayeeType;
  payeeId: string;
  ownerId: string;
  propertyId: string;
  due: string;
  posted: string;
  ref: string;
  lines: InvoiceLine[];
  recurrence?: RecurrenceInput;
  noteText?: string;
  applyGstHst: boolean;
  applyPst: boolean;
  gstHst: number;
  pst: number;
  useEnrollment: boolean;
  enrollmentId?: string;
};

export const useCreateInvoice = () => {
  const { sendNotification } = useNotification();
  const { ownerFor } = useAccountingContext();
  const { nextInvoice, queryLoading } = useFinancialSettings();
  const { glMapping } = useGlMapping();
  const [addClearableMutation, { error }] = useAddClearableMutation();
  useErrorNotifications(error);
  const { accountId } = useAuth();
  const { currentUser } = useCurrentUser();
  const [clearable, setClearable] = useState<ClearableFieldsFragment>();

  const arId = find(glMapping, { id: 'accountsReceivable' })?.glId ?? '';
  const gstHstId = find(glMapping, { id: 'gstHstPayable' })?.glId ?? '';
  const pstId = find(glMapping, { id: 'pstPayable' })?.glId ?? '';

  const createInvoice = async (
    values: CreateInvoiceFormFields,
    filesToUpload: File[],
    uploadFiles: ({ id }: { id: string }) => Promise<{
      successfulUploads: UploadSuccess[];
      failedUploads: UploadError[];
    }>
  ) => {
    const total = values.lines.reduce((amount, line) => safeSum(amount, line.invoiceAmounts), 0);
    const baseLine = {
      ownerId: values.ownerId ?? ownerFor(values.propertyId).id,
      propertyId: values.propertyId,
      ref: values.ref,
    };
    const firstUnitId = values.lines[0].unitId;
    const input: AddClearableInput = {
      posted: values.posted,
      due: values.due,
      payee: values.payee,
      payeeId: values.payeeId,
      ...(values.useEnrollment && values.enrollmentId ? { enrollmentId: values.enrollmentId } : {}),
      lines: [
        {
          ...baseLine,
          glId: arId,
          ...(firstUnitId && { unitId: firstUnitId }),
          amount:
            total + (values.applyGstHst ? values.gstHst : 0) + (values.applyPst ? values.pst : 0),
          description: values.lines[0].description,
        },
        ...spreadListIf(values.applyGstHst, [
          {
            ...baseLine,
            glId: gstHstId,
            ...(firstUnitId && { unitId: firstUnitId }),
            amount: values.gstHst,
            description: values.lines[0].description,
            gstInfo: GstInfo.Line103,
          },
        ]),
        ...spreadListIf(values.applyPst, [
          {
            ...baseLine,
            glId: pstId,
            ...(firstUnitId && { unitId: firstUnitId }),
            amount: values.pst,
            description: values.lines[0].description,
          },
        ]),
        ...values.lines.map(({ glId, unitId, invoiceAmounts, description }) => ({
          ...baseLine,
          glId,
          ...(unitId && { unitId }),
          amount: invoiceAmounts,
          description,
        })),
      ],
      notes:
        values?.noteText && currentUser?.name
          ? [{ createdName: currentUser.name, text: values.noteText }]
          : undefined,
      ...(values.recurrence ? { recurrence: values.recurrence } : {}),
    };
    const { data } = await addClearableMutation({
      variables: {
        input,
      },
      update(cache, result) {
        const id = cache.identify({ accountId, __typename: 'Books' });
        if (result.data?.addClearable?.success && result.data.addClearable.clearable) {
          const cachedClearable = result.data.addClearable.clearable;
          const ref = cache.writeFragment({
            data: result.data?.addClearable.clearable,
            fragment: ClearableFieldsFragmentDoc,
            fragmentName: API.Fragment.ClearableFields,
          });
          cache.modify({
            id,
            fields: {
              listClearables: (existing = {}, { storeFieldName }) => {
                const items = ensureArray<Reference[]>(existing.items);
                const args = storeFieldNameArgs<ListClearablesQueryVariables>(storeFieldName);
                const matches = clearablesFilterMatches(args.filter, cachedClearable);
                return matches ? { ...existing, items: [...items, ref] } : existing;
              },
              payeeClearables: (existing = { items: [] }, { storeFieldName }) => {
                const payeeId = cachedClearable.payeeId;
                return payeeId && storeFieldName.includes(payeeId)
                  ? {
                      ...existing,
                      items: [...existing.items, ref],
                    }
                  : existing;
              },
            },
          });
        }
        if (result.data?.addClearable?.success && result.data?.addClearable.recurrence) {
          cache.modify({
            id,
            fields: {
              recurrences: (existing = []) => [
                ...existing,
                cache.writeFragment({
                  data: result.data?.addClearable.recurrence,
                  fragment: RecurrenceFieldsFragmentDoc,
                  fragmentName: API.Fragment.RecurrenceFields,
                }),
              ],
            },
          });
        }
      },
    });

    const id = data?.addClearable.clearable?.sourceJournalEntry.jeId;
    setClearable(data?.addClearable.clearable);

    if (id && filesToUpload.length > 0) {
      await uploadFiles({ id });
    }

    sendNotification('Invoice posted', 'success');
  };

  return {
    createInvoice,
    nextInvoice,
    queryLoading,
    clearable,
  };
};
