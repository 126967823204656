import { useGetBooksActivationQuery } from 'api';
import { useAccountingEvents } from 'pages/accounting/hooks';

export const useBooksActivated = () => {
  const { data, loading } = useGetBooksActivationQuery();

  const activated = Boolean(data?.account?.books?.activatedZ);
  const autopay = Boolean(data?.account?.books?.autopay?.activated);
  const autopayVersion = data?.account?.books?.autopay?.version;
  const autopayActivationStatus = data?.account?.books?.autopay?.activationStatus;
  useAccountingEvents(activated);

  return { activated, autopay, autopayVersion, autopayActivationStatus, loading };
};
