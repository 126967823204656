import { ReactNode } from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
} from '@mui/material';
import clsx from 'clsx';
import { isString } from 'lodash';
import { capitalize } from 'system';

export type CheckboxFieldProps = CheckboxProps & {
  label?: string | ReactNode;
  helperText?: string;
  fullWidth?: boolean;
  error?: { message?: string };
  endAdornment?: ReactNode;
};

export function CheckboxField({
  label,
  color,
  disabled,
  className,
  helperText,
  fullWidth,
  endAdornment,
  error,
  ...checkboxProps
}: CheckboxFieldProps) {
  return (
    <FormControl fullWidth={fullWidth}>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={<Checkbox {...checkboxProps} color={color ?? 'primary'} />}
          label={isString(label) ? capitalize(label ?? '') : label}
          disabled={disabled}
          className={clsx(className)}
          sx={{ mr: 0, pl: '11px' }}
        />

        {endAdornment}
      </Stack>

      {Boolean(helperText || error?.message) && (
        <FormHelperText error={Boolean(error?.message)}>
          {helperText ?? error?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
}
