import gPhoneNumber from 'google-libphonenumber';
const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

export const validPhone = (value: string) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value, 'CA');
    const regionCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
    return (
      phoneUtil.isPossibleNumber(phoneNumber) &&
      phoneUtil.isValidNumberForRegion(phoneNumber, regionCode)
    );
  } catch (error) {
    return false;
  }
};

export const nullableValidPhone = (value?: string | null) => (value ? validPhone(value) : true);

export const formatPhone = (phone?: string) => {
  return phone
    ? phoneUtil.format(
        phoneUtil.parseAndKeepRawInput(phone, 'CA'),
        gPhoneNumber.PhoneNumberFormat.NATIONAL
      )
    : '';
};

export const callablePhone = (phone: string) => {
  return phoneUtil.format(
    phoneUtil.parseAndKeepRawInput(phone, 'CA'),
    gPhoneNumber.PhoneNumberFormat.E164
  );
};

export const textablePhone = (phone: string) => callablePhone(phone).replace(/\D/g, '');
