import { GetPropertyUnitsQuery, useGetPropertyUnitsQuery } from 'api';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useGetProvinceTaxDetails } from 'hooks/useGetProvinceTaxDetails';
import { useAccount } from 'pages/account/hooks/useAccount';
import { useEffect, useState } from 'react';
import { DEFAULT_GST, ensureArray } from 'system';

type Units = Required<Required<GetPropertyUnitsQuery>['property']>['units'];

export const useProperty = ({ propertyId }: { propertyId: string }) => {
  const [units, setUnits] = useState<Units>([]);
  const [name, setName] = useState('');
  const { getTaxDetails } = useGetProvinceTaxDetails();
  const [gstHstTaxRate, setGstTaxRate] = useState(0);
  const [pstTaxRate, setPstTaxRate] = useState(0);
  const [gstName, setGstName] = useState('GST');
  const [propertyProvince, setPropertyProvince] = useState('');
  const { data, error } = useGetPropertyUnitsQuery({ variables: { id: propertyId || 'invalid' } });
  useErrorNotifications(error);
  const { account } = useAccount();

  useEffect(() => {
    const province = data?.property?.address?.province?.toUpperCase();
    setPropertyProvince(province ?? '');
    setName(data?.property?.name ?? '');
    setUnits(ensureArray(data?.property?.units));
  }, [data]);

  const getTaxes = async (province?: string) => {
    const taxes = await getTaxDetails({ province });
    const gst = taxes.find((tax) => tax.gst);
    const pst = taxes.find((tax) => tax.label.toLowerCase().includes('pst'));
    setGstTaxRate(gst?.rate ?? DEFAULT_GST);
    setPstTaxRate(pst?.rate ?? 0);
    setGstName(gst?.label ?? 'GST');
  };

  useEffect(() => {
    const province = (
      data?.property?.address?.province ?? account?.address?.province
    )?.toUpperCase();
    getTaxes(province);
  }, [data, account]);

  return { units, name, gstHstTaxRate, gstName, pstTaxRate, propertyProvince };
};
