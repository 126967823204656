import {
  DepositTemplateItem,
  FractionInput,
  IncentiveEffect,
  MoveOutReason,
  ResidencyPathQuery,
} from 'api';
import jsonata from 'jsonata';
import { compact, groupBy, map, uniq } from 'lodash';
import { DateTime, DurationInput, Interval } from 'luxon';
import { fraction, number } from 'mathjs';
import { rrulestr } from 'rrule';
import {
  NonNullableField,
  emptyArray,
  ensureArray,
  formatCurrency,
  incentiveFeeId,
  multiplyFractions,
  rentFeeId,
  safeRound,
  safeSum,
  safeSumBy,
  sortByAttribute,
  toMathFraction,
} from 'system';
import { z } from 'zod';
import { LeaseFormFields } from '../types';

export const isTerminated = ({
  moveOutReason,
}: {
  moveOutReason?: NonNullableField<ResidencyPathQuery, 'unit.residency.moveOutReason'>;
}) =>
  moveOutReason &&
  [
    MoveOutReason.Terminated,
    MoveOutReason.Evicted,
    MoveOutReason.EarlyMoveOut,
    MoveOutReason.Sublet,
  ].includes(moveOutReason);

function nowFromStart(startZ?: string) {
  const realStart = startZ ?? DateTime.now().startOf('day').toISO();
  return DateTime.fromISO(realStart);
}

export const isOnOrBefore = (dateZ?: string) => (effect: { startZ: string; endZ?: string }) => {
  const startZ =
    dateZ && DateTime.fromISO(dateZ).isValid ? DateTime.fromISO(dateZ) : DateTime.now();

  return effect.endZ
    ? Interval.fromISO(`${effect.startZ}/${effect.endZ}`).contains(startZ)
    : DateTime.fromISO(effect.startZ) <= startZ;
};

export const isOnOrAfter = (dateZ?: string) => (effect: { startZ: string; endZ?: string }) => {
  const startZ =
    dateZ && DateTime.fromISO(dateZ).isValid ? DateTime.fromISO(dateZ) : DateTime.now();

  return effect.endZ
    ? Interval.fromISO(`${effect.startZ}/${effect.endZ}`).contains(startZ)
    : DateTime.fromISO(effect.startZ) >= startZ;
};

export const isAfter = (startZ: string) => (effect: { startZ: string; endZ?: string }) =>
  effect.endZ
    ? Interval.fromISO(`${effect.startZ}/${effect.endZ}`).isAfter(DateTime.fromISO(startZ))
    : DateTime.fromISO(effect.startZ) > DateTime.fromISO(startZ);

export function isCurrentSince(baseStartZ?: string) {
  const start = nowFromStart(baseStartZ);
  return (residencyOrEffect?: { startZ: string; endZ?: string }) =>
    Boolean(
      residencyOrEffect &&
        (residencyOrEffect.endZ
          ? Interval.fromISO(`${residencyOrEffect.startZ}/${residencyOrEffect.endZ}`).contains(
              start
            )
          : DateTime.fromISO(residencyOrEffect.startZ) <= start)
    );
}

export const endedDaysAgo = (daysAgo: number, residency?: { endZ?: string }) =>
  Boolean(!residency?.endZ || DateTime.fromISO(residency.endZ).diffNow('days').days <= daysAgo);

export const isCurrent = (residencyOrEffect?: { startZ: string; endZ?: string }) =>
  Boolean(
    residencyOrEffect &&
      (residencyOrEffect.endZ
        ? Interval.fromISO(`${residencyOrEffect.startZ}/${residencyOrEffect.endZ}`).contains(
            DateTime.now()
          )
        : +DateTime.fromISO(residencyOrEffect.startZ) <= +DateTime.now())
  );

export const isFuture = (residencyOrEffect?: { startZ: string }) =>
  residencyOrEffect && +DateTime.now() < +DateTime.fromISO(residencyOrEffect.startZ);

export const isPast = (residency?: { endZ?: string }) =>
  Boolean(residency?.endZ && +DateTime.fromISO(residency.endZ) <= +DateTime.now());

export const netEffect = (
  effects: Array<{ effect: number }> = [],
  {
    taxPct = 0,
    shareFrac = fraction(1),
  }: { taxPct?: number; shareFrac?: number | FractionInput | math.Fraction } = {}
) =>
  safeRound(
    number(
      multiplyFractions(safeSumBy('effect', effects), toMathFraction(shareFrac), safeSum(1, taxPct))
    ),
    2
  );

export const netFeesEffect = (effects?: { feeId: string; effect: number }[], feeIds?: string[]) =>
  netEffect(ensureArray(effects).filter(({ feeId }) => !feeIds || feeIds.includes(feeId)));

export const isRentEffect = (effect?: { feeId?: string }) => effect?.feeId == rentFeeId;

export const isIncentiveEffect = (effect?: { feeId?: string }): effect is IncentiveEffect =>
  effect?.feeId == incentiveFeeId;

export const isFeeEffect = (effect?: { feeId?: string }) =>
  effect?.feeId != rentFeeId && effect?.feeId != incentiveFeeId;

export const rateScheduleList = (
  effects: Array<{
    startZ: string;
    effect: number;
    description?: string;
    feeId: string;
  }> = emptyArray,
  afterZ = DateTime.now().toISO()
): Array<{ start: string; rent: number }> =>
  effects
    .filter(isRentEffect)
    .sort(sortByAttribute('startZ'))
    .reduce(
      (result, { effect, startZ }, i, arr) => [
        ...result,
        {
          start: DateTime.fromISO(startZ).toISODate(),
          rent: safeSum(effect, map(arr.slice(0, i), 'effect')),
        },
      ],
      [] as Array<{ start: string; rent: number }>
    )
    .filter(
      afterZ ? (schedule) => DateTime.fromISO(schedule.start) > DateTime.fromISO(afterZ) : Boolean
    );

export const rentRollFeesList = (
  effects: Array<{
    startZ: string;
    effect: number;
    description?: string;
    feeId: string;
  }> = emptyArray
) =>
  effects
    .filter(isFeeEffect)
    .sort(sortByAttribute('startZ'))
    .reduce(
      (rentRollFees, { effect, feeId }, i, arr) => [
        ...rentRollFees,
        {
          feeId,
          amount: safeSum(
            effect,
            map(
              arr.slice(0, i).filter((e) => e.feeId === feeId),
              'effect'
            )
          ),
        },
      ],
      [] as { feeId: string; amount: number }[]
    );

export const incentiveList = (
  effects: Array<{
    startZ: string;
    effect: number;
    description?: string;
    feeId: string;
  }> = emptyArray
) =>
  Object.values(
    groupBy(
      ensureArray(effects).filter(isIncentiveEffect).sort(sortByAttribute('startZ')),
      'incentiveId'
    )
  ).map(([{ startZ, description, incentiveId: id }, { effect: discountAmount, startZ: endZ }]) => ({
    id,
    description,
    type: 'rent',
    discountAmount,
    start: DateTime.fromISO(startZ).toISODate(),
    end: DateTime.fromISO(endZ).plus({ days: -1 }).toISODate(),
  }));

export const combineIncentiveEffects = (
  effects: Array<{
    startZ: string;
    effect: number;
    description?: string;
    feeId: string;
  }> = emptyArray
) =>
  Object.values(
    groupBy(
      ensureArray(effects).filter(isIncentiveEffect).sort(sortByAttribute('startZ')),
      'incentiveId'
    )
  ).map(([{ id, startZ, description = '', incentiveId }, { effect, startZ: endZ, feeId }]) => ({
    id,
    feeId,
    startZ,
    endZ,
    description,
    incentiveId,
    discountAmount: effect,
  }));

export const nextTerm = (rrule?: string, fromDate = new Date()) => {
  try {
    const nextDate = rrulestr(rrule ?? '').after(fromDate);
    if (!nextDate) {
      return;
    }
    const dt = DateTime.fromJSDate(nextDate);
    if (dt.isValid) return dt;
  } catch (e) {
    // no op
  }
};

/**
 * TODO: Deprecate and remove
 * @deprecated "Use `netFeesEffect` instead"
 */
export const calculateTotalIncentives = (incentives?: LeaseFormFields['incentives']) => {
  const currentDate = DateTime.now().startOf('day');
  const currentIncentives = incentives?.filter(
    (incentive) =>
      currentDate <= DateTime.fromISO(incentive?.end).startOf('day') &&
      currentDate >= DateTime.fromISO(incentive?.start).startOf('day')
  );
  const total =
    currentIncentives
      ?.map((incentive) => incentive.discountAmount)
      .reduce((prev, curr) => safeSum(prev, curr), 0) ?? 0;
  return total;
};

export function nextScheduledEffects(
  effects: Array<{ feeId: string; startZ: string; effect: number }> = emptyArray,
  startZ?: string,
  feeIds?: string[]
) {
  const [nextEffect, ...rest] = effects
    .filter(({ feeId }) => !feeIds || feeIds.includes(feeId))
    .filter(isAfter(startZ ?? DateTime.now().toISO()))
    .sort(sortByAttribute('startZ'));

  return nextEffect
    ? [
        nextEffect,
        ...rest.filter((e) =>
          DateTime.fromISO(nextEffect.startZ).hasSame(DateTime.fromISO(e.startZ), 'day')
        ),
      ]
    : [];
}

export const nextRateScheduleDisplay = (
  effects: Array<{ feeId: string; startZ: string; effect: number }> = emptyArray,
  startZ?: string,
  hideEffectiveDate?: boolean,
  gst?: number,
  shareFrac?: number | FractionInput | math.Fraction
) => {
  const nextEffects = nextScheduledEffects(effects, startZ);
  const nextDateTime = nextEffects?.[0] && DateTime.fromISO(nextEffects[0].startZ);

  const effectiveDateDisplay =
    hideEffectiveDate || !nextDateTime
      ? ''
      : nextDateTime.toRelative({
          unit: ['years', 'months', 'days', 'hours'],
          base: DateTime.now(),
        });

  const total = netEffect(nextEffects, { taxPct: gst ?? 0, ...(shareFrac && { shareFrac }) });

  return nextDateTime
    ? `${total < 0 ? '↓' : '↑'} ${formatCurrency(total)} ${effectiveDateDisplay}`
    : '';
};

export const dateWithinResidencyInterval =
  <TRecord extends { startZ?: string; endZ?: string }>(date: DateTime) =>
  ({ startZ, endZ }: TRecord) =>
    Interval.fromISO(`${startZ}/${endZ}`).contains(date);

export const isFutureDate = (date?: string) =>
  DateTime.local().startOf('day') < DateTime.fromISO(date ?? 'invalid').endOf('day');

export const residencyTerms = ({
  terms,
  renewZ,
  startZ,
}: {
  startZ: string;
  renewZ?: string;
  terms?: string[];
}) =>
  compact(uniq([startZ, ...(terms ?? []), renewZ]))
    .map((d) => DateTime.fromISO(d).toUTC().toISO())
    .sort((a, b) => a.localeCompare(b));

export const nextResidencyTerm = (
  props: { startZ: string; terms?: string[] } | undefined,
  since = props?.startZ ?? DateTime.now().toISO()
) =>
  props?.startZ && props?.terms
    ? residencyTerms({ terms: props.terms, startZ: props?.startZ }).find(
        (d) => DateTime.fromISO(d) > DateTime.fromISO(since)
      )
    : undefined;

export const refundableEvaluate = (
  expression?: string,
  residency?: { effects?: { effect?: number }[]; startZ?: string }
) => {
  try {
    return jsonata(expression ?? '').evaluate(
      {},
      {
        residency,
        netEffect,
        dateAdd: (date: string, input: DurationInput) =>
          DateTime.fromISO(date).plus(input).toISODate(),
      }
    );
  } catch (e) {
    console.warn(`JSONata error`, e);
    return null;
  }
};

export const toRefundableFor =
  ({ baseRent }: { baseRent: number }) =>
  ({
    id: templateId,
    name,
    defaultAmount,
    defaultAmountExpr,
    accrualStartExpr,
  }: DepositTemplateItem) => {
    const amount = defaultAmountExpr
      ? (refundableEvaluate(defaultAmountExpr, { effects: [{ effect: baseRent }] }) ?? baseRent)
      : (defaultAmount ?? baseRent);

    return {
      templateId,
      name,
      amount: z.number().catch(0).parse(amount),
      accrualStartExpr,
    };
  };
