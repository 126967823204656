import { Storage } from '@aws-amplify/storage';
import { Document } from 'api';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { useNotification } from 'hooks/useNotification';
import { isFunction, uniqBy } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { downloadFileS3 } from 'system';

export type UploadError = {
  file: File;
  error: unknown;
};

export type UploadSuccess = {
  localKey: string;
  key: string;
  file: File;
};

export const isUploadError = (obj: unknown): obj is UploadError =>
  'error' in (obj as { error?: unknown }) && typeof (obj as { name?: string }).name === 'string';

export const isUploadSuccess = (obj: unknown): obj is UploadSuccess =>
  typeof (obj as { key?: string }).key === 'string' &&
  typeof (obj as { localKey?: string }).localKey === 'string';

enum Notifications {
  ERROR_NO_BUCKET = 'Error, no bucket configured',
}

type GetFullS3KeyFunction = (filename: string) => string;

type UseUploadFilesProps = {
  bucket?: string;
  fullS3Key?: GetFullS3KeyFunction;
  maxFiles?: number;
};

export const joinFileNames = (list: { file: File }[], separator = ', ') =>
  list.map(({ file }) => `'${file.name}'`).join(separator);

export const toSharedKey = (key: string, recipient: string) => {
  const isSharedWithRecipient = ['shared-', `-${recipient}`].every((part) => key.includes(part));
  const isShared = /^shared-/.test(key);
  const keyParts = key.split('/');
  const filename = keyParts.pop() ?? '';
  if (isSharedWithRecipient) {
    return key;
  } else {
    return isShared
      ? `${key.replace('shared', `shared-${recipient}`)}`
      : keyParts.concat([`shared-${recipient}`, filename]).join('/');
  }
};

export const toUnsharedKey = (key: string, recipient: string) =>
  key.replace(`-${recipient}`, '').replace('shared/', '');

export const useUploadFiles = ({
  bucket = process.env.REACT_APP_DOCUMENT_BUCKET ?? '',
  fullS3Key,
  maxFiles,
}: UseUploadFilesProps) => {
  const filesToUploadRef = useRef<File[]>([]); // for immediate access to file changes without waiting for state
  const [filesToUpload, setFilesToUploadState] = useState<File[]>([]); // for reporting to consumers that files changed
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fileError, setFileError] = useState('');
  const { sendNotification } = useNotification();
  useAllErrors(fileError);

  const setFilesToUpload = (input: File[] | ((prev: File[]) => File[])) => {
    if (isFunction(input)) {
      filesToUploadRef.current = input(filesToUploadRef.current);
    } else {
      filesToUploadRef.current = input;
    }

    setFilesToUploadState(filesToUploadRef.current);
  };

  const removeUploadFile = (file: { name: string }) =>
    setFilesToUpload((prev) => prev.filter(({ name }) => name !== file.name));

  const addUploadFiles = (uploadedFiles: File[]) => {
    setFilesToUpload((prev) => uniqBy([...prev, ...uploadedFiles], 'name').slice(0, maxFiles));
  };

  const validConfig = useCallback(() => {
    if (!bucket) {
      setFileError(Notifications.ERROR_NO_BUCKET);
      console.error({ bucket });
      return false;
    }
    return true;
  }, [bucket]);

  const downloadFile = async (key: string, localFullS3Key?: GetFullS3KeyFunction) => {
    const getKey = fullS3Key ?? localFullS3Key;
    if (!getKey) return;

    await downloadFileS3(getKey(key), bucket);
  };

  const copyFile = async (sourceKey: string, destinationKey: string) => {
    if (!validConfig()) return;
    try {
      const file = await Storage.get(sourceKey, { bucket, download: true });
      await Storage.put(destinationKey, file.Body, { bucket, metadata: { sourceKey } });
    } catch (e) {
      sendNotification(`Error ${e}`, 'error');
      console.error(`Error copying file ${sourceKey}`);
    }
  };

  const removeFile = async (key: string) => {
    if (!validConfig()) return;
    try {
      await Storage.remove(key, { bucket });
    } catch (e) {
      sendNotification(`Error ${e}`, 'error');
      console.error(`Error removing file ${key}`);
    }
  };

  const moveFile = async (sourceKey: string, destinationKey: string) => {
    if (!validConfig()) return;
    try {
      await copyFile(sourceKey, destinationKey);
      await removeFile(sourceKey);
    } catch (e) {
      sendNotification(`Error ${e}`, 'error');
      console.error(`Error moving file ${sourceKey}`);
    }
  };

  const uploadFiles = useCallback(
    async (options?: { fullS3Key?: GetFullS3KeyFunction; prefix?: string }) => {
      const { fullS3Key: localFullS3Key, prefix = '' } = options ?? {};
      if (!validConfig()) return;
      const getKey = fullS3Key ?? localFullS3Key;
      if (!getKey) {
        console.error('no getKey function');
        return;
      }

      setFileError('');
      setUploading(true);

      const results = await Promise.all(
        filesToUploadRef.current.map((file) =>
          Storage.put(getKey(`${prefix}${file.name}`), file, { bucket })
            .then((res) => ({ ...res, file, localKey: `${prefix}${file.name}` }))
            .catch((e) => ({ file, error: e }))
        )
      );

      const failedUploads = results.filter(isUploadError);
      const successfulUploads = results.filter(isUploadSuccess);

      if (failedUploads.length) {
        sendNotification(`Error uploading ${joinFileNames(failedUploads)}`, 'error');
        console.error(`Error uploading ${failedUploads.length} files`, { failedUploads });
      }

      if (successfulUploads.length) {
        sendNotification(`Successfully uploaded ${joinFileNames(successfulUploads)}`, 'success');
      }

      setUploading(false);
      setFilesToUpload(failedUploads.map(({ file }) => file));

      return { successfulUploads, failedUploads };
    },
    [bucket, fullS3Key, sendNotification, validConfig]
  );

  const renameFile = async ({
    document: { key: filename },
    values: { name: newName },
  }: {
    document: Document;
    values: { name: string };
  }) => {
    if (!fullS3Key) {
      throw 'fullS3Function not provided';
    }
    const sourceKey = fullS3Key(filename);
    const destinationKey = fullS3Key(newName);
    return moveFile(sourceKey, destinationKey);
  };

  const deleteFile = async (key: string, localFullS3Key?: GetFullS3KeyFunction) => {
    if (!validConfig()) return;
    setFileError('');
    const getKey = fullS3Key ?? localFullS3Key;
    if (!getKey) return;

    setDeleting(true);

    try {
      await Storage.remove(getKey(key), { bucket });
      sendNotification(`Successfully deleted '${key}'`, 'success');
      setDeleting(false);
      return { success: true, key };
    } catch (error: unknown) {
      setFileError(JSON.stringify(error));
      console.error('Error removing file', { error });
      setDeleting(false);
      return { success: false, key, error };
    }
  };

  const getFile = async (key: string, localFullS3Key?: GetFullS3KeyFunction) => {
    const getKey = fullS3Key ?? localFullS3Key;
    if (!getKey) return;

    try {
      return (await Storage.get(getKey(key), { bucket })) as string;
    } catch (error) {
      setFileError(JSON.stringify(error));
      console.error('Error getting file', { error });
      return null;
    }
  };

  return {
    addUploadFiles,
    deleteFile,
    deleting,
    downloadFile,
    filesToUpload,
    getFile,
    removeUploadFile,
    uploadFiles,
    moveFile,
    renameFile,
    copyFile,
    removeFile,
    uploading,
  };
};
