import { ContactRelationship, PaymentMethod } from 'api';
import { DateTime } from 'luxon';
import { RefinementCtx, z } from 'zod';
import { nullableValidPhone, validPhone } from './validate';

export const coalesceTo =
  <TCoalesced>(to: TCoalesced) =>
  <TVal>(val: TVal): TCoalesced | NonNullable<TVal> =>
    val ?? to;

export const maybe = <TShape extends z.ZodTypeAny>(shape: TShape) =>
  shape.nullish().transform((x) => x ?? undefined);

export const intoISODate = <TVal>(val: TVal, ctx: RefinementCtx) => {
  const dt = DateTime.fromISO(String(val));
  const iso = dt.toUTC().toISODate();

  if (!dt.isValid || !iso) {
    ctx.addIssue({ code: z.ZodIssueCode.invalid_date });
    return z.NEVER;
  }

  return iso;
};

export type PaymentDetailsShape = z.infer<typeof paymentDetailsShape>;

const padDetailsShape = z.object({
  preferredPaymentMethod: z
    .literal(PaymentMethod.Pad)
    .nullish()
    .describe('Payment Method')
    .transform(coalesceTo(undefined)),
  padAmount: z.number().min(1).nullish().describe('PAD Amount').transform(coalesceTo(undefined)),
  padInstitution: z
    .string()
    .max(3)
    .nullish()
    .describe('PAD Institution')
    .transform(coalesceTo(undefined)),
  padTransit: z.string().max(5).nullish().describe('PAD Transit').transform(coalesceTo(undefined)),
  padAccount: z.string().max(12).nullish().describe('PAD Account').transform(coalesceTo(undefined)),
});

const nonPadDetailsShape = z.object({
  preferredPaymentMethod: z
    .enum([
      '',
      PaymentMethod.Cash,
      PaymentMethod.Cheque,
      PaymentMethod.CreditCard,
      PaymentMethod.Debit,
      PaymentMethod.Eft,
      PaymentMethod.None,
      PaymentMethod.Autopay,
      PaymentMethod.ETransfer,
    ])
    .nullish()
    .describe('Payment Method')
    .transform(coalesceTo(undefined))
    .transform((x) => (x === '' ? PaymentMethod.None : x)),
});

export const paymentDetailsShape = z
  .union([padDetailsShape, nonPadDetailsShape])
  .refine(({ preferredPaymentMethod, ...padDetails }) =>
    preferredPaymentMethod === PaymentMethod.Pad
      ? { preferredPaymentMethod, ...padDetails }
      : { preferredPaymentMethod }
  );

export const nullishNumberShape = (message = 'Must be not defined or a positive value') =>
  z
    .number()
    .optional()
    .transform(coalesceTo(undefined))
    .refine((value) => !value || value >= 0, {
      message,
    });

export const phoneShape = (message = 'Phone must be empty or a valid phone number') =>
  z.string().refine((value) => nullableValidPhone(value), {
    message,
  });

export const optionalPhoneShape = (message = 'Phone must be empty or a valid phone number') =>
  z
    .string()
    .optional()
    .refine((value) => nullableValidPhone(value), {
      message,
    });

export const optionalEmailShape = (message = 'Email must be empty or a valid email address') =>
  z
    .string()
    .optional()
    .refine((value) => !value || /^\S+@\S+\.\S+$/.test(value), {
      message,
    });

export const zodDateAsString = ({
  message,
  required,
}: { message?: string; required?: boolean } = {}) =>
  z
    .string()
    .refine(
      (v) =>
        !v
          ? !required
          : typeof v === 'string'
            ? v === '' || DateTime.fromISO(v).isValid
            : DateTime.fromJSDate(v).isValid,
      {
        message: message ?? 'Invalid Date',
      }
    );

export const zodRequiredString = (message?: string) =>
  z.string().refine((value) => value.trim() !== '', {
    message: message ?? 'Must select a value',
  });

export const addressShape = z.object({
  suite: maybe(z.string()),
  street: maybe(z.string()),
  city: maybe(z.string()),
  country: maybe(z.string()),
  province: maybe(z.string()),
  postal: maybe(z.string()),
});

export const emailShape = (message = 'Email must be empty or a valid email address') =>
  z.string().email(message).optional();

export const emergencyContactShape = () =>
  z.object({
    name: z
      .string()
      .min(1, { message: 'Name is required' })
      .max(200, { message: 'Max 200 characters allowed' }),
    relationship: z.nativeEnum(ContactRelationship).optional().nullable(),
    phone: z
      .string()
      .min(1, { message: 'Phone is required' })
      .refine(validPhone, { message: 'Emergency Contact Phone must be a valid phone number' }),
  });

export const nullishPercentageShape = (message = 'Must be not defined or valid percentage value') =>
  z
    .string()
    .optional()
    .transform(coalesceTo(undefined))
    .refine((value) => !value || (parseFloat(value) >= 0 && parseFloat(value) <= 100), {
      message,
    });
