import { ButtonProps, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useAuth } from 'context';
import { useToggle } from 'hooks/useToggle';
import { NavLink } from 'react-router-dom';
import { AuthNavItem } from './authNav';
import { ListItemButton } from './NavListItem.styles';

type NavListItemProps = AuthNavItem & {
  short: boolean;
  disabled?: boolean;
  sx?: ButtonProps['sx'];
  isActive?: boolean;
};

const NavButton = ({
  icon: Icon,
  onClick,
  label,
  badge,
  showFullSizeIcon,
  short,
  disabled,
  sx,
  isActive,
}: Omit<NavListItemProps, 'to' | 'toggleId' | 'restrictions'>) => {
  return (
    <ListItemButton isActive={isActive} disabled={disabled} sx={sx} onClick={() => onClick?.()}>
      {Icon && (showFullSizeIcon || short) && (
        <Tooltip title={label} aria-label={label}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        </Tooltip>
      )}

      {!short && (
        <>
          <ListItemText primary={label} sx={{ m: 0 }} />
          {badge && badge}
        </>
      )}
    </ListItemButton>
  );
};

export default function NavListItem({ to, restrictions, toggleId, ...props }: NavListItemProps) {
  const { roles } = useAuth();
  const isAuthorized = restrictions ? roles && restrictions.some((r) => roles.includes(r)) : true;
  const toggleEnabled = useToggle({ id: toggleId });
  const enabled = toggleId ? toggleEnabled : true;

  return isAuthorized && enabled ? (
    to ? (
      <NavLink to={to} style={{ textDecoration: 'none' }} end={to === '/'}>
        {({ isActive }) => <NavButton {...props} isActive={isActive} />}
      </NavLink>
    ) : (
      <NavButton {...props} />
    )
  ) : (
    <></>
  );
}
