import { Storage } from '@aws-amplify/storage';
import {
  Address,
  CorrespondenceMethod,
  MoveOutReason,
  PaymentMethod,
  RequestFieldsFragment,
  RequestListFieldsFragment,
  Visit,
} from 'api';
import equal from 'fast-deep-equal/es6';
import KSUID from 'ksuid';
import {
  castArray,
  compact,
  constant,
  isEmpty,
  isFunction,
  isNil,
  isObject,
  map,
  mapValues,
  omitBy,
  reject,
} from 'lodash';
import { get } from 'lodash/fp';
import { DateTime, DurationUnits, Zone } from 'luxon';
import { isCurrent, isFuture } from 'pages/properties/property/units/unit/residency/util';
import { Fragment, createElement } from 'react';
import * as Yup from 'yup';
import { REFRESH_TIMEOUT_MS, emptyArray } from '../constant';
import {
  DateLike,
  Occupancy,
  OccupancyType,
  Provinces,
  RequestDelay,
  RequestDisplayLabel,
  RequestStatus,
} from '../types';

export const noOp = () => {
  /* no-op */
};

export const statusIn =
  (...statuses: string[]) =>
  (obj?: { status?: string }) =>
    Boolean(obj?.status && statuses.includes(obj.status));

export const DateFormats = {
  ShortMonthDay: 'LLL d',
  ShortWeekday: 'ccc',
  LongWeekday: 'cccc',
};

export const consents = [
  { value: 'notice-waived-consent', label: 'Waive the 24 hour notice period' },
  {
    value: 'entry-consent',
    label: 'Allow a technician to enter your unit when you are not present',
  },
];

export const safeRound = (arg: number, digits = 2) =>
  Math.round(arg * Math.pow(10, digits)) / Math.pow(10, digits);

export const parseCurrencyFloat = (currencyStr?: string): number =>
  currencyStr ? safeRound(parseFloat(String(currencyStr).replace(/[,$]/g, ''))) : 0;

export const parseJSON = (jsonString = '{}', reviver?: Parameters<JSON['parse']>[1]) => {
  try {
    return JSON.parse(jsonString, reviver);
  } catch (e) {
    return {};
  }
};

export const getCurrentResidentName = (
  unit?: {
    allResidencies?: Array<{
      startZ: string;
      endZ?: string;
      residents: Array<{ resident: { id: string; name: string }; leaseHolder?: boolean }>;
    }>;
  },
  maxDisplayed = 2
) => {
  const relevantResidencies = compact([
    ...(unit?.allResidencies?.filter(isCurrent) ?? []),
    ...(unit?.allResidencies?.filter(isFuture) ?? []),
  ]);
  const residents =
    unit && 'allResidencies' in unit
      ? relevantResidencies[0]?.residents.map(({ resident, leaseHolder }) => ({
          ...resident,
          leaseHolder,
        }))
      : undefined;

  if (!residents || residents.length === 0) {
    return null;
  }

  const leaseHolders = residents.filter((tenant) => tenant.leaseHolder);
  const displayed = (leaseHolders.length > 0 ? leaseHolders : residents).slice(0, maxDisplayed);
  const more = leaseHolders.length > displayed.length ? ' (...)' : '';
  const name = compact(map(displayed, 'name')).join(' & ');

  return `${name} ${more}`.trim();
};

const labelFromStatusAndDelay: Partial<
  Record<RequestStatus, Partial<Record<RequestDelay, RequestDisplayLabel>>>
> = {
  [RequestStatus.SUBMITTED]: {
    [RequestDelay.LATE]: RequestDisplayLabel.OVERDUE,
  },
  [RequestStatus.CONFIRMED]: {
    [RequestDelay.LATE]: RequestDisplayLabel.DELAYED,
    [RequestDelay.OVERDUE]: RequestDisplayLabel.MISSED,
  },
};

export const delayStatusLabel = (record?: { status?: string; delay?: string }) => {
  const statusDelay = `${(record?.status ?? 'UNKNOWN').toUpperCase()}/${(
    record?.delay ?? ''
  ).toUpperCase()}`;

  return statusDelay === 'SCHEDULED/LATE'
    ? RequestDisplayLabel.DELAYED
    : statusDelay === 'SCHEDULED/OVERDUE'
      ? RequestDisplayLabel.MISSED
      : (record?.status ?? 'UNKNOWN');
};

export const computeRequestStatusLabel = ({
  status,
  delay,
  visits,
}: Pick<RequestFieldsFragment | RequestListFieldsFragment, 'visits' | 'status' | 'delay'> = {}) =>
  isRequestStatus(status)
    ? hasAssignedOrScheduledVisits(visits)
      ? RequestDisplayLabel['CONFIRMED']
      : isRequestDelay(delay)
        ? (labelFromStatusAndDelay[status]?.[delay] ?? status)
        : hasRequestedVisits(visits)
          ? RequestDisplayLabel['NEW VISIT REQUESTED']
          : hasDeclinedVisits(visits)
            ? RequestDisplayLabel['VISIT DECLINED']
            : (RequestDisplayLabel[status] ?? status)
    : RequestDisplayLabel.DEFAULT;

const hasAssignedOrScheduledVisits = (visits?: Visit[]) =>
  visits?.some(
    (v) =>
      ['SCHEDULED', 'ASSIGNED'].includes(v?.status ?? '') &&
      !Object.keys(RequestDelay).includes(v?.delay ?? '')
  );
const hasRequestedVisits = (visits?: Visit[]) => visits?.some((v) => v?.status === 'REQUESTED');
const hasDeclinedVisits = (visits?: Visit[]) => visits?.some((v) => v?.status === 'DECLINED');
const isRequestStatus = (label?: string): label is RequestStatus =>
  Object.keys(RequestStatus).includes(label ?? '');

const isRequestDelay = (label?: string): label is RequestDelay =>
  Object.keys(RequestDelay).includes(label ?? '');

export const formatAddress = (address?: Address, includeSuite = false) => {
  return [
    includeSuite ? address?.suite : '',
    address?.street,
    address?.city,
    address?.province,
    address?.postal,
  ]
    .filter(Boolean)
    .join(', ');
};

export const formatTime = (dateZ: DateLike) =>
  parseDates(dateZ)[0].toLocaleString(DateTime.TIME_SIMPLE);

export const formatFullDate = (dateZ: DateLike) => {
  return parseDates(dateZ)[0].toLocaleString(DateTime.DATE_FULL);
};

export const formatDate = (dateZ: DateLike, format = DateTime.DATE_FULL) => {
  return parseDates(dateZ)[0].toLocaleString(format);
};

export const formatNth = (n: number) => {
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);
  const rules = new Intl.PluralRules('en-US', { type: 'ordinal' });
  return `${n}${suffixes.get(rules.select(n))}`;
};

export const formatCurrencyCR = (rawAmount?: number) =>
  formatCurrency(rawAmount, { negativeStyle: 'cr' });

export const formatCurrency = (
  rawAmount?: number,
  options?: {
    showDollarSign?: boolean;
    hideZeroCents?: boolean;
    negativeStyle?: 'default' | 'cr' | 'parens';
    hideZeroAmount?: boolean;
  }
): string => {
  const {
    showDollarSign = true,
    hideZeroCents = false,
    negativeStyle = 'default',
    hideZeroAmount = false,
  } = options ?? {};
  const amount = rawAmount ? (safeRound(rawAmount) === 0 ? 0 : safeRound(rawAmount)) : 0;

  const prefix = negativeStyle === 'cr' && amount < 0 ? 'CR' : '';
  const diplayedAmount = prefix && amount < 0 ? -amount : amount;

  const formattedAmount = Intl.NumberFormat('en-CA', {
    ...(showDollarSign ? { style: 'currency', currency: 'CAD' } : {}),
    ...(negativeStyle === 'parens' ? { currencySign: 'accounting' } : {}),
    ...(hideZeroCents && Math.floor(diplayedAmount) === diplayedAmount
      ? { maximumFractionDigits: 0 }
      : {}),
    ...(!showDollarSign ? { maximumFractionDigits: 2, minimumFractionDigits: 2 } : {}),
  }).format(diplayedAmount);

  const display = amount === 0 && hideZeroAmount ? '' : `${prefix}${formattedAmount}`;

  return display;
};

export const formatPercentage = (percent: number): string => {
  return percent.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
  });
};

export const formatNumber = (number?: number) => {
  return number?.toLocaleString() ?? '';
};

export const formatBoolean = (value: boolean) => (value ? 'Yes' : 'No');

export const prefixFilename = ({ filename, prefix }: { filename?: string; prefix: string }) =>
  filename?.replace(/\/([^/]+)([/]?)$/, `/${prefix}-$1$2`);

export const sentenceCase = (sentence: string) =>
  sentence.replace(/[A-Za-z]/, (c) => c.toUpperCase());

export const titleCase = (snakeCase: string) =>
  map(snakeCase.replace(/_/g, ' ').split(' '), capitalize).join(' ');

export const padColons = (str: string) => str.replace(/:/g, ': ');

export const capitalize = (name: string) =>
  ['hvac', 'gst', 'hst', 'pst', 'pad', 'eft', 'sms', 'coi']
    .reduce(
      (result, acronym) => result.replace(acronym.toLowerCase(), (match) => match.toUpperCase()),
      name.toLowerCase()
    )
    .replace(/^./, (c) => c.toUpperCase());

export const avatarInitials = (name = '') =>
  name
    .replace(/[^\w\s]/gi, '')
    .split(/[ ]+/)
    .filter((_w, i, arr) => i === 0 || i === arr.length - 1)
    .map((word) => word[0])
    .join('')
    .toUpperCase();

export const stringsOnly = (arr: unknown[]): string[] =>
  arr.filter((item: unknown) => typeof item === 'string') as string[];

export const splitCamelCase = (camelCase: string) => camelCase.replace(/([a-z])([A-Z])/g, '$1 $2');

export const hashCode = (inputs: string[]) =>
  inputs
    .join('#')
    .split('')
    .reduce((a, c) => (Math.imul(31, a) + c.charCodeAt(0)) | 0, 0);

export const toFlatPropertyMap = (
  obj: Record<string | number | symbol, unknown>
): Record<string | number | symbol, unknown> => {
  const flattenRecursive = (
    record: Record<string | number | symbol, unknown> = {},
    parentProperty?: string,
    propertyMap: Record<string | number | symbol, unknown> = {}
  ) => {
    for (const [key, value] of Object.entries(record)) {
      const property = parentProperty ? `${parentProperty}.${key}` : key;
      if (value && typeof value === 'object' && Object.keys(value).length > 0) {
        flattenRecursive(value as Record<string | number | symbol, unknown>, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
};

export const isNilOrEmptyObject = (
  item: unknown
): item is null | undefined | Record<string, never> =>
  isNil(item) || (typeof item === 'object' && !Array.isArray(item) && isEmpty(item));

export const stripNonData = <T = unknown>(
  item: T
): T extends null | undefined | Record<string, never> ? undefined : T => {
  return (
    isNilOrEmptyObject(item)
      ? undefined
      : Array.isArray(item)
        ? reject(item.map(stripNonData), isNilOrEmptyObject)
        : isObject(item) && !DateTime.isDateTime(item)
          ? omitBy(
              mapValues(item, stripNonData),
              (value, key) => key === '__typename' || isNilOrEmptyObject(value)
            )
          : item
  ) as T extends null | undefined | Record<string, never> ? undefined : T;
};

export const index = <T = Record<string, unknown>>(obj: T, idx: number) => ({ ...obj, index: idx });

export const yupPasswordString = () =>
  Yup.string()
    .required('New password is required to update')
    .min(8, 'New password must be at least 8 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[{}'"/\\.,<>:;|_~`+=[\]()-^@$!%*#?&])[A-Za-z\d{}'"/\\.,<>:;|_~`+=[\]()-^@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    );

export const yupPeriodAsString = (message?: string) =>
  Yup.string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v.isLuxonDateTime
        ? v.toString().substring(0, 7)
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString().substring(0, 7)
          : DateTime.fromJSDate(v).toString().substring(0, 7);
    })
    .test('isValidPeriod', message ?? 'Invalid Period', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : context.schema.spec.presence !== 'required';
    });

export const yupDateAsString = (message?: string) =>
  Yup.string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v.isLuxonDateTime
        ? v.toString().substring(0, 10)
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString().substring(0, 10)
          : DateTime.fromJSDate(v).toString().substring(0, 10);
    })
    .test('isValidDate', message ?? 'Invalid Date', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : context.schema.spec.presence !== 'required';
    });

export const yupDateTimeAsString = () =>
  Yup.string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v.isLuxonDateTime
        ? v.toString()
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString()
          : DateTime.fromJSDate(v).toString();
    })
    .test('isValidDate', 'Invalid Date', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : context.schema.spec.presence !== 'required';
    });

export const yupTimeAsString = () =>
  Yup.string()
    .transform((_, v) => {
      if (!v) {
        return undefined;
      }
      return v.isLuxonDateTime
        ? v.toString().substring(11, 19)
        : typeof v === 'string'
          ? v === ''
            ? v
            : DateTime.fromISO(v).toString().substring(11, 19)
          : DateTime.fromJSDate(v).toString().substring(11, 19);
    })
    .test('isValidTime', 'Invalid Time', (v, context) => {
      return v
        ? DateTime.fromISO(v ?? 'invalid').isValid
        : context.schema.spec.presence !== 'required';
    });

export const yupRequiredString = () => Yup.string().required() as Yup.StringSchema<string>;

export const parseDates = <TDates extends unknown[]>(...dates: TDates) =>
  dates.map((date) =>
    DateTime.isDateTime(date)
      ? date
      : date instanceof Date
        ? DateTime.fromJSDate(date)
        : typeof date === 'string'
          ? DateTime.fromISO(date)
          : DateTime.invalid('invalid date')
  );

export const parseDatesWithTz = <TDates extends unknown[]>(
  zone: Zone | string = 'America/Edmonton',
  ...dates: TDates
) =>
  parseDates(
    ...dates.map((dateLike) =>
      typeof dateLike === 'string' && dateLike.length === 10
        ? DateTime.fromISO(dateLike, { zone }).toUTC()
        : dateLike
    )
  ).map((d) => d.setZone(zone));

export const parseOccupancy = (str?: string) =>
  Object.values(OccupancyType).includes(str as Occupancy) ? (str as Occupancy) : 'rental';

export const safeSum = (...args: (number | number[])[]) =>
  args.flat().reduce((a, c) => safeRound(a + c), 0);

export const safeSumBy = <
  TProp extends string,
  TRec extends Partial<Record<TProp, number | number[]>>,
>(
  prop: TProp,
  ...args: (TRec | TRec[])[]
) => safeSum(compact(args.flatMap((x) => castArray(x).flatMap(get(prop)))));

export const spreadIf = <P = unknown, T = unknown>(predicate: P, obj?: T) =>
  predicate ? (obj ? obj : predicate) : {};

export const spreadListIf = <P = unknown, T = unknown>(predicate: P, list?: T) =>
  predicate ? (list ? list : []) : [];

export const durationFromToday = (dateStr: string, units: DurationUnits = ['month']) =>
  DateTime.fromISO(dateStr).diff(DateTime.fromISO(DateTime.local().toISODate()), units, {
    conversionAccuracy: 'longterm',
  });

export const queryParams = (qs: URLSearchParams, params: string[]): string[] =>
  params.map((param) => qs.get(param) ?? '');

export const queryString = (params: Record<string, string>) =>
  new URLSearchParams(params).toString();

export const skipProps = (...props: string[]) => ({
  shouldForwardProp: (prop: string) => !props.includes(prop),
});

export const hasId = <T extends { id?: string } = Record<string, unknown>>(
  obj: T
): obj is T & { id: string } => typeof obj.id === 'string';

export const ensureArray = <T = unknown[] | null | undefined>(arr?: T) =>
  arr ?? (emptyArray as unknown as NonNullable<T>);

export const paymentMethodOptions = Object.values(PaymentMethod)
  .filter((p) => ![PaymentMethod.Autopay, PaymentMethod.None].includes(p))
  .sort()
  .map((id) => ({
    id,
    label: capitalize(sentenceCase(splitCamelCase(id))),
    text: capitalize(sentenceCase(splitCamelCase(id))),
    value: id,
  }));

export const insert = <T>(arr: T[], idx: number, newItem: T) => [
  ...arr.slice(0, idx),
  newItem,
  ...arr.slice(idx),
];

export const replace = <T>(arr: T[], idx: number, newItem: T) => [
  ...arr.slice(0, idx),
  newItem,
  ...arr.slice(idx + 1),
];

export const insertOrReplace = <T>(
  arr: T[],
  predicate: (item: T) => boolean,
  newItem: T | ((src?: T) => T)
) => {
  const idx = arr.findIndex(predicate);
  const getItem = () => (isFunction(newItem) ? newItem(arr[idx]) : newItem);
  return idx === -1 ? [...arr, getItem()] : replace(arr, idx, getItem());
};

export const cents = (amount: number) => Math.round(amount * 100) % 100;

export const csvQuoted = (str?: unknown) =>
  !isNil(str) ? (['number', 'boolean'].includes(typeof str) ? String(str) : `"${str}"`) : '';

export const toCsv = (data?: Record<string, unknown> | Record<string, unknown>[]): string => {
  const safeData = data ?? {};

  const columns = Object.keys(Array.isArray(safeData) ? safeData[0] : safeData).map((key) => ({
    key,
    label: sentenceCase(splitCamelCase(key)),
  }));

  const rows = Array.isArray(data) ? data : [data];

  return compact([
    columns.map(({ label }) => csvQuoted(label)).join(),
    ...rows.map((row) => columns.map(({ key }) => csvQuoted(row?.[key])).join()),
  ]).join('\n');
};

export const toCsvBlob = (data?: Record<string, unknown> | Record<string, unknown>[]): Blob =>
  new Blob([toCsv(data)], { type: 'text/csv' });

export const download = (data: Blob, options?: { filename?: string }) => {
  const { filename = 'export.csv' } = { ...options };

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(data);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFromUrl = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.id = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const invalidate = (_: unknown, { DELETE }: { DELETE: unknown }) => DELETE;

export const getSecurityDepositLabel = (address?: Address) =>
  [Provinces.ON, 'ONTARIO'].includes((address?.province ?? '').toUpperCase())
    ? "Last month's rent"
    : 'Security Deposit';

export const getLeaseEndLabel = ({ end }: { end?: string }) =>
  end ? DateTime.fromISO(end).toLocaleString(DateTime.DATE_FULL) : 'No end date set';

export const getAutoRenewLabel = ({
  autoRenewSetup,
}: {
  autoRenewSetup?: { enabled?: boolean; termMonths?: number };
}) =>
  autoRenewSetup?.enabled && (!autoRenewSetup.termMonths || autoRenewSetup.termMonths === 1)
    ? 'Monthly'
    : autoRenewSetup?.enabled
      ? `Every ${autoRenewSetup.termMonths} months`
      : 'Not Applicable';

export const correspondenceMethodOptions = Object.values(CorrespondenceMethod)
  .sort()
  .map((id) => ({
    id,
    label: capitalize(sentenceCase(splitCamelCase(id))),
    text: capitalize(sentenceCase(splitCamelCase(id))),
    value: id,
  }));

export const ifDifferent =
  <T = unknown>(newValue: T) =>
  (prev: T) =>
    equal(prev, newValue) ? prev : newValue;

export const ifTruthy =
  <T = unknown>(newValue?: T) =>
  (prev: T) =>
    newValue ? newValue : prev;

export const capitalizeOptions = (enumObject: Record<string, string>) =>
  Object.values(enumObject).map((value) => capitalize(value));

export const hasProperty = <T extends Record<PropertyKey, unknown>>(
  obj: T,
  prop: PropertyKey
): prop is keyof T => prop in obj;

export const renderNothing = constant(createElement(Fragment));

export const shallowOmit = <
  TRecord extends Record<string, unknown> | undefined,
  TKey extends keyof NonNullable<TRecord>,
>(
  obj: TRecord,
  keys: TKey[]
) => {
  const clone = { ...obj } as NonNullable<TRecord>;
  for (const key of keys) {
    delete (clone as NonNullable<TRecord>)[key];
  }

  return clone as Omit<typeof clone, TKey>;
};

export const moveOutReasonOptions = Object.values(MoveOutReason).map((id) => ({
  id,
  text: titleCase(id).replace('Move Out', 'Move-out'),
}));

/**
 * https://stackoverflow.com/a/73900724/3092891
 * Run concurrent promises with a maximum concurrency level
 * @param concurrency The number of concurrently running promises
 * @param funcs An array of functions that return promises
 * @returns a promise that resolves to an array of the resolved values from the promises returned by funcs
 */
export const concurrent = <V>(concurrency: number, funcs: (() => Promise<V>)[]): Promise<V[]> =>
  new Promise((res, rej) => {
    let idx = -1;
    const p: Promise<V>[] = [];
    for (let i = 0; i < Math.max(1, Math.min(concurrency, funcs.length)); i++) runPromise();
    function runPromise() {
      if (++idx < funcs.length) (p[p.length] = funcs[idx]()).then(runPromise).catch(rej);
      else if (idx === funcs.length) Promise.all(p).then(res).catch(rej);
    }
  });

export const matches =
  <TMatch extends Record<string, unknown>>(match: TMatch) =>
  <TRecord extends Record<string, unknown>>(record: TRecord): record is Extract<TRecord, TMatch> =>
    Object.entries(match).every(([key, value]) => value === record[key]);

export const ksuid = () => KSUID.randomSync().string;

export const tuple = <T extends unknown[]>(...args: T) => args;

export const isOpenStatus = (status?: string) =>
  !status ||
  (<RequestStatus[]>[
    RequestStatus.APPROVAL,
    RequestStatus.SUBMITTED,
    RequestStatus.CONFIRMED,
    RequestStatus.STARTED,
    RequestStatus.PAUSED,
  ]).includes(status as RequestStatus);

export const downloadFileS3 = async (key: string, bucket?: string) => {
  const fileBucket = bucket ?? process.env.REACT_APP_DOCUMENT_BUCKET ?? '';
  const downloadUrl = await Storage.get(key, {
    bucket: fileBucket,
  });
  if (typeof downloadUrl === 'string') {
    open(downloadUrl, '_blank');
  }
};

export const sleep = (duration = REFRESH_TIMEOUT_MS) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  const promise = new Promise((resolve) => {
    timeoutId = setTimeout(resolve, duration);
  });

  return promise.finally(() => clearTimeout(timeoutId));
};
