import { Box, Stack, Typography } from '@mui/material';
import { Document } from 'api';
import {
  CheckboxField,
  ConfirmModal,
  DocumentTable,
  Dropzone,
  Modal,
  RenameDocumentModal,
} from 'components';
import { useDocuments } from 'hooks/useDocuments';
import { useModalControl } from 'hooks/useModalControl';
import { useListS3 } from 'hooks/useS3Storage';
import { toSharedKey, toUnsharedKey, useUploadFiles } from 'hooks/useUploadFiles';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import plur from 'plur';
import { GeneratedDocument } from 'system';
import { ActionButton } from './Documents.styles';
import { useMemo } from 'react';

const isSelfShared = ({ key }: { key: string }) => /^shared\//.test(key);

export function Documents({
  disabled,
  type,
  source,
  generatedDocuments,
  shareActions,
  getExtraActions,
  extraProps,
}: Parameters<typeof useDocuments>[0] & {
  generatedDocuments?: GeneratedDocument[];
  shareActions?: (document: Document) => {
    shareDestinations: string[];
    recipientId?: string;
    recipientType: 'owner' | 'tenant';
    recipientLabel?: string;
  }[];
  getExtraActions?: (key: string) => {
    id: string;
    onAction: (document: GeneratedDocument) => void;
    icon?: JSX.Element;
    actionName: string;
  }[];
  disabled?: boolean;
}) {
  const [handleShowDropzone, handleHideDropzone, showDropzone] = useModalControl();
  const [handleShowRename, handleHideRename, showRename, documentToRename] =
    useModalControl<Document>();
  const [handleShowConfirm, handleHideConfirm, showConfirm, keyToDelete] =
    useModalControl<string>();

  const { fullS3Key } = useDocuments({
    type,
    source,
    extraProps,
  });

  const {
    addUploadFiles,
    deleteFile,
    deleting,
    renameFile,
    downloadFile,
    filesToUpload,
    removeUploadFile,
    uploadFiles,
    uploading,
    moveFile,
    copyFile,
    removeFile,
  } = useUploadFiles({ fullS3Key });

  const {
    exists,
    loading,
    addObject,
    updateObject,
    removeObject,
    objects: documents,
  } = useListS3(fullS3Key?.(''), {
    bucket: process.env.REACT_APP_DOCUMENT_BUCKET,
  });

  const filteredDocuments = useMemo(
    () => documents.filter((doc) => !isSelfShared(doc)),
    [documents]
  );

  const handleRename = async ({
    document,
    values,
  }: {
    document: Document;
    values: { name: string };
  }) => {
    await renameFile({ document, values });
    updateObject(document.key, { key: values.name });
  };

  const handleShare = async ({
    document,
    recipientId,
    recipientType,
    shareDestinations,
  }: {
    document: Document | GeneratedDocument;
    recipientId?: string;
    recipientType: string;
    shareDestinations: string[];
  }) => {
    const sourceKey = get(document, 'fullKey', fullS3Key(document.key));

    const isSharedWithRecipient = new RegExp(`-${recipientType}${recipientId}`).test(sourceKey);
    const destinationKey = (isSharedWithRecipient ? toUnsharedKey : toSharedKey)(
      document.key,
      `${recipientType}${recipientId}`
    );
    const fullDestinationKey = fullS3Key(destinationKey);
    if (isSharedWithRecipient) {
      await Promise.all(shareDestinations.map((destination) => removeFile(destination)));
    } else {
      await Promise.all(shareDestinations.map((destination) => copyFile(sourceKey, destination)));
    }

    await moveFile(sourceKey, fullDestinationKey);
    updateObject(document.key, { key: destinationKey });
  };

  const [showModal, hideModal, modalOpen, replaceFileNames] =
    useModalControl<[name: string, replace: boolean][]>();

  const saveUploads = async () => {
    const result = await uploadFiles();

    result?.successfulUploads?.forEach(({ file }) => {
      addObject({
        key: file.name,
        size: file.size,
        createdZ: DateTime.utc().toISO(),
      });
    });
  };

  const handleSave = async () => {
    const filesToReplace = filesToUpload.filter((f) => exists(f.name));

    if (filesToReplace.length === 0) {
      await saveUploads();
    } else {
      showModal(filesToReplace.map((f) => [f.name, false]));
    }
  };

  const handleDelete = async (key: string) => {
    const result = await deleteFile(key);
    if (result?.success) {
      return removeObject(key);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {!showDropzone && documents.length > 0 && (
        <ActionButton
          disabled={disabled}
          color="primary"
          aria-roledescription="Add a document"
          onClick={handleShowDropzone}
        >
          Upload
        </ActionButton>
      )}

      {(showDropzone || (!loading && documents.length === 0)) && !disabled && (
        <Dropzone
          filesToUpload={filesToUpload}
          onCancel={showDropzone ? handleHideDropzone : undefined}
          onDrop={addUploadFiles}
          onRemove={removeUploadFile}
          onSave={handleSave}
          uploading={uploading}
          cancelAfterSave={true}
        />
      )}

      <>
        <DocumentTable
          {...{
            disabled,
            loading,
            documents: filteredDocuments,
            shareActions,
            generatedDocuments,
            getExtraActions,
          }}
          downloadFn={downloadFile}
          shareFn={handleShare}
          renameFn={handleShowRename}
          deleteFn={handleShowConfirm}
        />
        <RenameDocumentModal
          onClose={handleHideRename}
          open={showRename}
          document={documentToRename}
          onSubmit={handleRename}
        />

        {replaceFileNames?.[0] && (
          <ConfirmModal
            double
            open={modalOpen}
            onClose={hideModal}
            title={`Duplicate document ${plur('name', replaceFileNames.length)}`}
            onConfirm={async () => {
              replaceFileNames.forEach(([name, replaceFile]) => {
                if (!replaceFile) {
                  removeUploadFile({ name });
                }
              });

              await saveUploads();
            }}
          >
            <Stack gap={1}>
              <Typography variant="body1">
                {replaceFileNames.length} {plur('document', replaceFileNames.length)} with that name
                already exist. Would you like to replace{' '}
                {plur('it', 'them', replaceFileNames.length)}?
              </Typography>

              <CheckboxField
                checked={replaceFileNames.every(([_, replace]) => replace)}
                onChange={(_, checked) =>
                  showModal(replaceFileNames.map(([fileName]) => [fileName, checked]))
                }
                label={
                  <Typography key={`replace_all`} variant="body2">
                    Replace all?
                  </Typography>
                }
              />

              <Stack>
                {replaceFileNames.map(([name, replace]) => (
                  <Stack direction="row" gap={1}>
                    <CheckboxField
                      checked={replace}
                      onChange={(_, checked) =>
                        showModal(
                          replaceFileNames.map(([fileName, fileReplace]) => [
                            fileName,
                            fileName === name ? checked : fileReplace,
                          ])
                        )
                      }
                      label={
                        <Typography key={`replace_${name}`} variant="body2">
                          {name}
                        </Typography>
                      }
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </ConfirmModal>
        )}

        {keyToDelete && (
          <Modal
            onClose={handleHideConfirm}
            open={showConfirm}
            title="Delete Document"
            actions={{
              cancel: {
                label: 'Cancel',
                onClick: () => {
                  handleHideConfirm();
                },
              },
              confirm: {
                label: 'Delete',
                disabled: /^shared-/.test(keyToDelete),
                loading: deleting,
                onClick: async () => {
                  await handleDelete(keyToDelete);
                  handleHideConfirm();
                },
              },
            }}
          >
            <Typography color="textSecondary">
              {/^shared-/.test(keyToDelete)
                ? 'This document is shared. Please unshare before deleting.'
                : 'Are you sure you would like to delete this document?'}
            </Typography>
          </Modal>
        )}
      </>
    </Box>
  );
}
