import { Reference } from '@apollo/client';
import {
  AddClearableInput,
  API,
  BalanceType,
  ClearableFieldsFragmentDoc,
  GstInfo,
  ListClearablesQueryVariables,
  PayeeType,
  RecurrenceFieldsFragmentDoc,
  RecurrenceInput,
  useAddClearableMutation,
} from 'api';
import { useAuth } from 'context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useErrorNotifications } from 'hooks/useErrorNotifications';
import { useNotification } from 'hooks/useNotification';
import { UploadError, UploadSuccess } from 'hooks/useUploadFiles';
import { ensureArray, safeSum } from 'system';
import { useAccountingContext } from '../context';
import { clearablesFilterMatches, storeFieldNameArgs } from '../utils';
import { useGlAccounts } from './useGlAccounts';
import { useGlMapping } from './useGlMapping';

export type BillLine = {
  glId: string;
  unitId?: string;
  description: string;
  billAmounts: number;
};

export type EnterBillFormFields = {
  payeeId: string;
  payee: PayeeType;
  ownerId: string;
  propertyId: string;
  due: string;
  posted: string;
  ref: string;
  lines: BillLine[];
  recurrence?: RecurrenceInput;
  noteText?: string;
  applyGstHst: boolean;
  gst?: number;
};

export const useEnterBill = () => {
  const { sendNotification } = useNotification();
  const { ownerFor } = useAccountingContext();
  const { mappedGlId } = useGlMapping();
  const { findGlAccount } = useGlAccounts();
  const [addClearableMutation, { error }] = useAddClearableMutation();
  useErrorNotifications(error);
  const { accountId } = useAuth();
  const { currentUser } = useCurrentUser();

  const apId = mappedGlId('accountsPayable') ?? '';
  const gstId = mappedGlId('gstHstPayable') ?? '';

  const enterBill = async (
    values: EnterBillFormFields,
    filesToUpload: File[],
    uploadFiles: ({ id }: { id: string }) => Promise<{
      successfulUploads: UploadSuccess[];
      failedUploads: UploadError[];
    }>
  ) => {
    const total = values.lines.reduce((result, line) => safeSum(result, line.billAmounts), 0);

    const baseLine = {
      ownerId: values.ownerId ?? ownerFor(values.propertyId).id,
      propertyId: values.propertyId,
      ref: values.ref,
    };

    const firstUnitId = values.lines[0].unitId;
    const input: AddClearableInput = {
      posted: values.posted,
      due: values.due,
      payee: values.payee,
      payeeId: values.payeeId,
      lines: [
        {
          ...baseLine,
          glId: apId,
          ...(firstUnitId && { unitId: firstUnitId }),
          amount: values.applyGstHst ? total + (values.gst ?? 0) : total,
          description: values.lines[0].description,
        },
        ...(values.applyGstHst
          ? [
              {
                ...baseLine,
                glId: gstId,
                ...(firstUnitId && { unitId: firstUnitId }),
                amount: (values.gst ?? 0) * -1,
                description: values.lines[0].description,
                gstInfo: GstInfo.Line106,
              },
            ]
          : []),
        ...values.lines.map(({ glId, unitId, billAmounts, description }) => {
          const lineGlAccount = findGlAccount(glId);
          const amount =
            lineGlAccount?.balanceType === BalanceType.Debit ? billAmounts : -billAmounts;
          return {
            ...baseLine,
            glId,
            ...(unitId && { unitId }),
            amount,
            description,
          };
        }),
      ],
      notes:
        values?.noteText && currentUser?.name
          ? [{ createdName: currentUser.name, text: values.noteText }]
          : undefined,
      ...(values.recurrence ? { recurrence: values.recurrence } : {}),
    };

    try {
      const { data } = await addClearableMutation({
        variables: {
          input,
        },
        update(cache, { data: mutationResponse }) {
          const id = cache.identify({ accountId, __typename: 'Books' });
          if (mutationResponse?.addClearable?.success && mutationResponse.addClearable.clearable) {
            const cachedClearable = mutationResponse.addClearable.clearable;
            const ref = cache.writeFragment({
              data: cachedClearable,
              fragment: ClearableFieldsFragmentDoc,
              fragmentName: API.Fragment.ClearableFields,
            });
            cache.modify({
              id,
              fields: {
                listClearables: (existing = {}, { storeFieldName }) => {
                  const items = ensureArray<Reference[]>(existing.items);
                  const args = storeFieldNameArgs<ListClearablesQueryVariables>(storeFieldName);
                  const matches = clearablesFilterMatches(args.filter, cachedClearable);
                  return matches ? { ...existing, items: [...items, ref] } : existing;
                },
                payeeClearables: (existing = { items: [] }, { storeFieldName }) => {
                  const payeeId = cachedClearable.payeeId;
                  return payeeId && storeFieldName.includes(payeeId)
                    ? {
                        ...existing,
                        items: [...existing.items, ref],
                      }
                    : existing;
                },
              },
            });
          }
          if (
            mutationResponse?.addClearable?.success &&
            mutationResponse?.addClearable.recurrence
          ) {
            cache.modify({
              id,
              fields: {
                recurrences: (existing = []) => [
                  ...existing,
                  cache.writeFragment({
                    data: mutationResponse.addClearable.recurrence,
                    fragment: RecurrenceFieldsFragmentDoc,
                    fragmentName: API.Fragment.RecurrenceFields,
                  }),
                ],
              },
            });
          }
        },
      });

      const id = data?.addClearable.clearable?.sourceJournalEntry.jeId;

      if (id && filesToUpload.length > 0) {
        await uploadFiles({ id });
      }

      sendNotification('New bill has been posted', 'success');
    } catch (e) {
      sendNotification('Error adding bill', 'error');
    }
  };

  return {
    enterBill,
  };
};
