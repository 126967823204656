import { Box, BoxProps, Typography, Grid as _Grid } from '@mui/material';
import { styled } from '@mui/styles';
import Lottie, { Options } from 'react-lottie';
import defaultAnimationData from '../../components/icons/lotties/loadingBox.json';

const Grid = styled(_Grid)(() => ({
  height: 500,
}));

export default function LoadingBox({
  animationData,
  message = 'Moving in tenants...',
  options,
  sx,
}: {
  animationData?: unknown;
  message?: string;
  options?: Omit<Options, 'animationData'>;
  sx?: BoxProps['sx'];
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      ...options?.rendererSettings,
      preserveAspectRatio: 'xMidYMid slice',
    },
    ...options,
    animationData: animationData ?? defaultAnimationData,
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box sx={sx}>
        <Lottie options={defaultOptions} height={150} width={150} />
        <Typography variant="subtitle1" align="center" sx={{ mt: 1 }}>
          {message}
        </Typography>
      </Box>
    </Grid>
  );
}
