import { useListAllPropertiesQuery } from 'api';
import { useMemo } from 'react';
import { useErrorNotifications } from './useErrorNotifications';
import { usePaginateAllQuery } from './usePaginateAllQuery';
import { useTeams } from './useTeams';

export const useProperties = () => {
  const { loading, error, items } = usePaginateAllQuery(useListAllPropertiesQuery, {
    getNextToken: (d) => d?.account?.listProperties?.nextToken,
    getItems: (d) => d.account?.listProperties?.items,
  });
  useErrorNotifications(error);

  const { isPropertyVisible } = useTeams();

  const properties = useMemo(
    () => items?.filter(({ id }) => isPropertyVisible(id)),
    [isPropertyVisible, items]
  );

  return { error, loading, properties };
};
